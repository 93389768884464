<!--
 * @Author: wangwei
 * @Date: 2020-12-28 16:06:28
 * @LastEditTime: 2023-01-17 20:11:04
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @Description: In User Settings Edit
 * @FilePath: /java-pc/src/views/member/order/List.vue
-->
<template>
    <div>
        <div class="sld_order_list">
            <MemberTitle :memberTitle="L['采购计划详情']"></MemberTitle>
            <div class="container">
                <div style="padding: 0 20px 10px 20px; border: 1px solid #eeeeeeff">
                    <div
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            border-bottom: 1px solid #eeeeeeff;
                            padding-bottom: 10px;
                        "
                    >
                        <h3 style="margin-top: 10px; font-size: 16px">
                            {{ L["采购计划详情"] }}
                        </h3>

                        <div style="margin-top: 12px; display: flex">
                            <el-button @click="closeOrder">关闭计划</el-button>
                            <el-button
                                @click="handleEdit"
                                v-show="topTable.data.state !== 4 && topTable.data.state !== 3"
                                >编辑</el-button
                            >
                            <el-button @click="goback">返回</el-button>
                            <div class="Submit" v-show="topTable.data.state !== 4" @click="onSubmit(ruleFormRef)">
                                提交
                            </div>
                        </div>
                    </div>
                    <div class="sld_order_navs">
                        <div class="sld_order_nav_item addBorder distance">
                            <p>
                                <span class="fontColor">创建时间 :</span>
                                {{ topTable.data.createTime }}
                            </p>
                            <p class="stateStyle">
                                <!-- <span
                                    v-if="platform == 2"
                                    :class="
                                        topTable.data.state == 0 || topTable.data.state == 4
                                            ? 'yellow'
                                            : topTable.data.state == 1
                                            ? 'green'
                                            : topTable.data.state == 2
                                            ? 'bule'
                                            : topTable.data.state == 3
                                            ? 'gray'
                                            : ''
                                    "
                                    >大石集采</span
                                > -->
                                <span class="yellow" v-if="topTable.data.state == 0">待报价</span>
                                <span class="green" v-if="topTable.data.state == 1">报价中</span>
                                <span class="bule" v-if="topTable.data.state == 2">已报价</span>
                                <span class="gray" v-if="topTable.data.state == 3">已关闭</span>
                                <span class="yellow" v-if="topTable.data.state == 4">审批中</span>
                            </p>
                            <p v-if="store.state.memberInfo.isNeedMyPrice == 1" class="UncompletedQuotation">
                                企业未填我的报价
                            </p>
                        </div>
                        <div class="sld_order_nav_item addBorder distance3">
                            <div class="item_title">
                                <h3>基本信息</h3>
                                <div class="item_type">
                                    <p v-if="topTable.data.type == 0">单次采购</p>

                                    <p v-if="topTable.data.type == 1">月度采购</p>
                                </div>
                            </div>
                            <p>
                                <span class="fontColor">采购单号:</span><span>{{ topTable.data.memberPlanCode }}</span>

                                <img v-if="topTable.data.source == 0" src="@/assets/yongyou.png" />
                                <img v-if="topTable.data.source == 1" src="@/assets/dashiLogo.png" />

                                <img v-if="topTable.data.source == 2" src="@/assets/dashijicai@2x.png" />
                            </p>
                            <p>
                                <span class="fontColor" v-if="topTable.data.source == 0">请购单号:</span
                                ><span>{{ topTable.data.bizBillCode }}</span>
                            </p>
                            <p>
                                <span class="fontColor">创建人:</span
                                ><span style="margin-right: 10px"> {{ topTable.data.prayName }} </span
                                ><span>
                                    {{ topTable.data.operator }}
                                </span>
                            </p>
                            <p>
                                <span class="fontColor">创建人部门:</span
                                ><span v-if="topTable.data.deptName">
                                    {{ topTable.data.deptName }}
                                </span>
                                <span v-if="!topTable.data.deptName">
                                    {{ topTable.data.corpName }}
                                </span>
                            </p>
                            <p style="display: flex">
                                <span class="fontColor">备注:</span>
                                <span
                                    style="max-width: 550px; display: inline-block"
                                    v-if="topTable.data?.desc?.split('').length < 200"
                                    >{{ topTable.data.desc ? topTable.data.desc : "无" }}</span
                                >

                                <el-tooltip
                                    v-else
                                    class="box-goodsItem"
                                    effect="dark"
                                    :content="topTable.data?.desc"
                                    placement="top-start"
                                >
                                    <span class="text-ellipsisss" style="max-width: 350px">{{
                                        topTable.data?.desc
                                    }}</span>
                                </el-tooltip>
                            </p>
                        </div>
                        <div class="sld_order_nav_item distance2">
                            <div class="item_title">
                                <h3>收货信息</h3>
                                <div class="item_title_right">
                                    <span class="white" @click="changeAddress">更换地址</span>
                                    <span class="addAddress" @click="addAddress">新建地址</span>
                                </div>
                            </div>

                            <div class="content_left flex_column_between_start">
                                <p>
                                    <span class="fontColor">收货人：</span
                                    ><span>{{ topTable.data.receivingName }}</span>
                                </p>
                                <p>
                                    <span class="fontColor">{{ L["联系方式"] }}：</span
                                    >{{ topTable.data.receivingMobile }}
                                </p>
                                <p>
                                    <span class="fontColor addressStyle"> {{ L["收货地址"] }}:</span
                                    ><span>{{ topTable.data.receivingAddress }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    style="
                        padding: 0 20px 15px 20px;
                        border: 1px solid #eeeeeeff;
                        margin-top: 20px;
                        margin-bottom: 24px;
                    "
                >
                    <div class="goodsList">
                        <div style="display: flex; justify-content: space-between; align-items: center; height: 40px">
                            <h3 style="margin-bottom: 10px; margin-top: 10px; font-size: 16px">
                                {{ "采购商品" }}
                            </h3>
                            <!--<p
                class="viewDetails"
                v-if="store.state.memberInfo.isLookOrder == 1"
                @click="getmySupplierList(null, tableData.data)"
              >
                邀请我的供应商
              </p>-->
                        </div>
                        <el-divider />
                        <el-form :inline="true" :model="tableData.data" :rules="rules" ref="ruleFormRef">
                            <el-table
                                :data="tableData.data"
                                style="width: 100%; margin-top: 22px"
                                default-expand-all
                                ref="multipleTableRef"
                                @selection-change="handleSelectionChange"
                                border
                                :header-cell-style="tableHeaderColor"
                            >
                                <el-table-column prop="goodsName" label="商品名称" />
                                <el-table-column prop="goodsSpec" label="规格型号" align="center" width="120">
                                    <template #default="scope">
                                        <span v-if="scope.row.goodsSpec"
                                            >{{ scope.row.goodsSpec }},{{ scope.row.goodsMarque }}</span
                                        >
                                        <span v-if="!scope.row.goodsSpec">{{ scope.row.goodsMarque }}</span>
                                    </template>
                                </el-table-column>
                                <!--<el-table-column
                prop="goodsMarque"
                label="型号"
                align="center"
                width="80"
              />-->
                                <el-table-column prop="goodsUnit" label="单位数量" align="center" width="120">
                                    <template #default="scope">
                                        {{ scope.row.purchaseNum }}{{ scope.row.goodsUnit }}
                                    </template>
                                </el-table-column>
                                <!--<el-table-column
                prop="purchaseNum"
                label="采购"
                align="center"
                width="80"
              />-->

                                <el-table-column prop="needDate" label="需求日期" align="center" width="120" />
                                <el-table-column prop="desc" label="备注">
                                    <template #default="scope">
                                        <div class="desc-clss" :title="scope.row.desc">
                                            {{ scope.row.desc }}
                                        </div>
                                        <div class="flex_row_start_center" style="margin-top: 5px; width: 100%">
                                            <span
                                                class="clickBtn"
                                                v-if="scope.row.purchasePic"
                                                @click="previewImg(scope.row.purchasePic)"
                                                >查看图片</span
                                            >
                                            <!-- <i class="iconfont icon-fenleitupiandabiaoti" style="font-size: 22px;cursor: pointer;" v-if="scope.row.purchasePic" @click="previewImg(scope.row.purchasePic)"></i> -->
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="state" align="start" width="352">
                                    <template #header>
                                        <div>
                                            <span>我的报价</span
                                            ><span class="yellowColor" style="font-size: 12px; margin-left: 5px"
                                                >(如未询到报价,供应商名称请填写无,价格请填写0)</span
                                            >
                                            <p
                                                style="
                                                    color: red;
                                                    font-size: 12px;
                                                    text-align: center;
                                                    font-weight: 400;
                                                "
                                                v-show="topTable.data.state == 4"
                                            >
                                                审批中的订单无法输入企业报价
                                            </p>
                                        </div>
                                    </template>
                                    <template #default="scope">
                                        <!--<div style="display: flex">-->

                                        <!--<el-form-item prop="mySupplierName">

                    </el-form-item>-->
                                        <!--<el-form-item
                      :prop="'tableData.data[' + scope.$index + '].myPrice'"
                    >-->
                                        <div class="inputDiv">
                                            <input
                                                v-model="scope.row.mySupplierName"
                                                :disabled="scope.row.state == 3 || topTable.data.state == 4"
                                                placeholder="请输入供应商名称"
                                                style="width: 190px; margin-right: 10px"
                                                size="small"
                                                maxlength="30"
                                                show-word-limit
                                                type="text"
                                                :class="scope.row.borderColor == true ? 'borderColor' : ''"
                                                @input="mySupplierNameHandleChange($event, scope.row)"
                                            />
                                            <input
                                                v-model="scope.row.myPrice"
                                                :disabled="scope.row.state == 3 || topTable.data.state == 4"
                                                placeholder="请输入价格"
                                                style="width: 130px"
                                                size="small"
                                                @input="handleChange($event, scope.row)"
                                                :class="scope.row.isColor == true ? 'borderColor' : ''"
                                            />
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="desc" label="状态" align="center">
                                    <template #default="scope">
                                        <el-tag type="primary" v-if="scope.row.state == 0 || scope.row.state == 1"
                                            >待报价</el-tag
                                        >

                                        <!--<el-tag type="success" v-if="scope.row.state == 1">待报价</el-tag>-->
                                        <el-tag type="success" v-if="scope.row.state == 2">已报价</el-tag>

                                        <el-tag type="info" v-if="scope.row.state == 3">已关闭</el-tag>
                                        <el-tag type="info" v-if="scope.row.state == 4">审批中</el-tag>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="desc" label="操作" align="center">
                                    <template #default="scope">
                                        <!--<span
                      class="clickBtn"
                      style="margin-right: 15px"
                      @click="getmySupplierList(scope.row.memberNeedCode, [])"
                      v-if="store.state.memberInfo.isLookOrder == 1"
                    >
                      邀请我的供应商
                    </span>-->
                                        <span class="clickBtn" @click="handleCloseItem(scope.row.memberNeedCode)"
                                            >关闭</span
                                        >
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-form>
                        <el-divider border-style="dotted" />
                        <div class="generateOrder">
                            <div class="generateOrder_left">
                                <span>
                                    <span>
                                        <span class="fontColor">采购计划总数:</span>
                                        <span>{{ route.query.goodsNum }}</span
                                        >件
                                    </span>
                                    <span>
                                        <span class="fontColor" style="margin-left: 20px">已关闭:</span>
                                        <span>{{
                                            route.query.goodsNum == tableData.data.length
                                                ? 0
                                                : route.query.goodsNum - tableData.data.length
                                        }}</span
                                        >件
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--<el-dialog
      v-model="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      top="13%"
      width="30%"
    >
      <div>
        <div class="my-header">
          <div class="modalTitle"><span>选择支付方式</span></div>
          <div
            style="font-size: 24px; color: rgb(163 158 158); cursor: pointer"
            @click="dialogVisible = false"
          >
            ×
          </div>
        </div>
        <div class="invoice_con flex">
          <div
            class="no_select_invoice flex_row_center_center"
            :class="{ activeLt: index != 0 }"
            v-for="(item, index) in paymentList.data"
            :key="index"
            @click="handlePayment(index)"
          >
            <img :src="selected" v-if="activeIndex == index" alt />
            <img
              :src="not_select"
              v-if="activeIndex != index && item.disabled"
              alt
            />
            <span v-if="item.disabled">{{ item.label }}</span>
            <div
              class="date_box"
              v-show="index == 1 && activeIndex == 1 && orderBelongsVal"
            >
              <el-date-picker
                v-model="selectDate"
                type="date"
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                placeholder="请选择日期"
                size="small"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div style="display: flex; justify-content: center">
          <div class="Clear" @click="dialogVisible = false">取消</div>
          <div class="Submit2" @click="createOrder">确定</div>
        </div>
      </template>
    </el-dialog>-->

        <el-dialog
            title="更换地址"
            v-model="show_select_address"
            customClass="select_address_width"
            :before-close="handleClose"
            lock-sroll="false"
            width="500px"
        >
            <div class="out_stock_dialog address_con">
                <div
                    v-for="(item, index) in address_list.data"
                    :key="index"
                    @click="selectAddress(index)"
                    :class="{
                        address_item: true,
                        flex_column_start_start: true,
                        select: current_address_index == index
                    }"
                >
                    <span>{{ L["收货人"] }}:{{ item.memberName }}</span>
                    <span>{{ L["联系方式"] }}:{{ item.telMobile }}</span>
                    <div class="address_text" :title="item.addressAll + item.detailAddress">
                        <span>{{ L["收货地址"] }}:</span>
                        <span class="overflow_ellipsis_clamp2">{{ item.addressAll }} {{ item.detailAddress }}</span>
                    </div>
                    <img v-if="current_address_index == index" class="selected" :src="invoice_selected" alt />
                </div>
            </div>
            <div class="btn_con flex_row_center_center">
                <div class="remove red" @click="confirmChangeAddress()" style="margin-top: 10px">确认</div>
            </div>
        </el-dialog>
        <SldAddressOperation
            v-if="addressVisibleFlag"
            @close="close"
            @refreshAddress="refreshAddress"
            :visibleFlag="true"
            :getDetailData="getDetailData"
            :type="type"
            :memberPlanCode="topTable.data.memberPlanCode"
        />
        <!-- 查看备注中图片弹窗 -->
        <el-dialog v-model="descImgVisible" title="查看图片" width="820px">
            <div class="desc-image__preview">
                <el-image
                    v-for="(url, index) in srcList"
                    :key="index"
                    :src="url"
                    :zoom-rate="1.2"
                    :max-scale="7"
                    :min-scale="0.2"
                    :preview-src-list="srcList"
                    :initial-index="4"
                    fit="cover"
                    :class="{ active: index != 0 }"
                />
            </div>
        </el-dialog>
        <el-dialog
            v-model="SupplierVisible"
            title="我的供应商"
            :width="710"
            :close-on-click-modal="false"
            :before-close="closeModal"
        >
            <div style="position: relative">
                <el-form ref="ruleFormRef" :model="mySupplierParam">
                    <el-form-item label="供应商名称" prop="supplierName">
                        <el-input v-model="mySupplierParam.supplierName" style="width: 195px" size="small" />
                    </el-form-item>
                    <div class="searchBtn2">
                        <div class="search" @click="onSubmits()">搜索</div>
                        <el-button @click="resetForms(ruleFormRef)" size="small">重置</el-button>
                    </div>
                </el-form>
            </div>

            <el-table
                :data="mySupplierList.data"
                v-model="mySupplierList.data"
                :border="true"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="40" />
                <el-table-column type="index" width="80" label="序号" align="center" />
                <el-table-column property="supplierName" label="供应商" width="400" align="center" />
                <el-table-column label="操作" align="center" width="149">
                    <template #default="scope">
                        <span
                            style="color: #103276ff; margin-right: 10px; cursor: pointer"
                            @click="toSupplierHomePage(scope.row.id, scope.row.supplierName)"
                            v-if="scope.row.status >= 4"
                            >查看</span
                        >
                        <span style="color: #103276ff; cursor: pointer" @click="inviteQuote([scope.row.id])"
                            >邀请报价</span
                        >
                    </template>
                </el-table-column>
            </el-table>
            <div style="display: flex; justify-content: center">
                <el-pagination
                    size="small"
                    class="mt-4"
                    style="margin-top: 10px"
                    v-if="mySupplierParam.total != 0"
                    v-model:current-page="mySupplierParam.pageNum"
                    v-model:page-size="mySupplierParam.pageSize"
                    :page-sizes="[10, 20, 30, 40]"
                    :small="small"
                    :disabled="disabled"
                    :background="background"
                    :total="mySupplierParam.total"
                    @size-change="handleSizeChanges"
                    @current-change="handleCurrentChanges"
                />
            </div>
            <template #footer>
                <div style="display: flex; justify-content: flex-end">
                    <div style="display: flex; margin-bottom: 10px">
                        <div class="search2" @click="addSupplier()">邀请供应商</div>
                        <div class="search3" @click="inviteQuote(SupplietParam?.supplierIdList)">邀请报价</div>
                    </div>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
import { reactive, getCurrentInstance, ref, onMounted } from "vue";
import SldAddressOperation from "../../../components/PlanAddressOperation.vue";
import { ElInput, ElMessage, ElMessageBox, ElTimeline, ElTimelineItem } from "element-plus";
// import { Search } from '@element-plus/icons-vue'
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { apiUrl } from "../../../utils/config";
import axios from "axios";
import SldCommonEmpty from "../../../components/SldCommonEmpty";
import MemberTitle from "../../../components/MemberTitle";
import { platform } from "@/utils/config";

import download from "downloadjs";
export default {
    name: "order-list",
    components: {
        ElInput,
        ElTimeline,
        ElTimelineItem,
        SldCommonEmpty,
        MemberTitle,
        SldAddressOperation
    },
    setup() {
        // const balance = require("../../assets/buy/balance.png");
        const store = useStore();
        const router = useRouter();
        const route = useRoute();
        const memberInfo = reactive({ data: store.state.memberInfo });
        const { proxy } = getCurrentInstance();
        const addressVisibleFlag = ref(false);
        const L = proxy.$getCurLanguage();
        const not_select = require("../../../assets/buy/not_select.png");
        const selected = require("../../../assets/buy/selected.png");
        const invoice_selected = require("../../../assets/buy/invoice_selected.png");
        const current_state = ref(""); //当前列表订单状态
        const evaluate_state = ref(0); //是否是待评价
        const fits = ["fill", "contain", "cover", "none", "scale-down"];
        const orderSn = ref(""); //订单id
        const descImgVisible = ref(false); //备注中图片弹窗
        const srcList = ref([]); //备注中图片url集合
        const multipleTableRef = ref();
        const dialogVisible = ref(false);
        const AllMoney = ref(0);
        const show_select_address = ref(false);
        const address_list = reactive({ data: [] });
        const topTable = reactive({ data: [] });
        const orderBelongsVal = ref(false); //是否是三方订单
        const activeIndex = ref(0);
        const selectDate = ref("");
        const ruleFormRef = ref();
        const payData = reactive({ data: {} }); //会员拥有的支付、付款数据
        const SupplierVisible = ref(false);
        const mySupplierList = reactive({ data: [] });
        const SupplietParam = reactive({
            extMemberOrderDetailList: [
                {
                    id: "",
                    memberNeedCode: "",
                    supplierUserId: ""
                }
            ],
            supplierIdList: [],
            memberNeedCode: []
        });
        const mySupplierParam = reactive({
            memberId: store.state.memberInfo.memberId,
            supplierName: "",
            pageNum: 1,
            pageSize: 10,
            total: 0,
            memberNeedCode: [],
            supplierIdList: []
        });
        const validateNumber = (rule, value, callback) => {
            console.log(value, 900);
            return new Promise((resolve, reject) => {
                if (!value) {
                    reject(new Error("请输入报价"));
                } else {
                    resolve();
                }
                if (isNaN(value)) {
                    reject(new Error("请输入正确报价"));
                } else {
                    resolve();
                }
            });
        };
        const rules = {
            myPrice: [
                { required: true, message: "请输入供应商报价", trigger: "blur" },
                {
                    validator: validateNumber,
                    message: "请输入正确报价"
                }
            ],
            mySupplierName: [{ required: true, message: "请输入供应商名称", trigger: "blur" }]
        };
        //const paymentList = reactive({
        //  data: [
        //    //企业会员
        //    { label: "预付全款", value: 1, disabled: false },
        //    { label: "货到付款", value: 2, disabled: false },
        //    { label: "账期支付", value: 3, disabled: false },
        //  ],
        //});
        const pageData = reactive({
            pageNum: 1,
            pageSize: 10,
            total: 0
        });
        const multipleSelection = ref([]);
        const current_address_index = ref(0);
        const type = ref("add");
        //我的供应商输入
        const mySupplierNameHandleChange = (e, item) => {
            console.log(e.target.value, item);
            if (e.target.value) {
                //item.borderColor = false;
                if (!item.myPrice) {
                    item.isColor = true;
                }
                tableData.data.forEach((i) => {
                    i.borderColor = false;
                });
            } else {
                if (item.myPrice) {
                    item.borderColor = true;
                } else {
                    item.borderColor = false;
                    item.isColor = false;
                }
            }
            if (e.target.value.trim() !== e.target.value) {
                //alert("输入框中不允许包含空格！");
                // 可以选择清除输入框内容或执行其他操作
                item.borderColor = true;
            }
        };

        //关闭弹窗
        const close = () => {
            addressVisibleFlag.value = false;
        };
        //弹出新建地址窗口
        const addAddress = () => {
            addressVisibleFlag.value = true;
        };
        const refreshAddress = (addressInfo, addressId) => {
            console.log(addressInfo, addressId);
            tableData.data.receiverAddress = addressInfo.addressAll;
            tableData.data.receivingMobile = addressInfo.telMobile;
            tableData.data.receivingName = addressInfo.memberName;
        };
        const OtherList = reactive({
            data: []
        });
        const debounce = (fn, time) => {
            let timer = null;
            return (...arges) => {
                if (timer) {
                    clearTimeout(timer);
                }
                timer = setTimeout(() => {}, time);
            };
        };
        const handleChange = (e, item) => {
            if (e.target.value) {
                //item.isColor = false;
                if (!item.mySupplierName) {
                    item.borderColor = true;
                }
                tableData.data.forEach((i) => {
                    item.isColor = false;
                });
            } else {
                if (item.mySupplierName) {
                    item.isColor = true;
                } else {
                    item.borderColor = false;
                    item.borderColor = false;
                }
            }
            if (e.target.value.trim() !== e.target.value) {
                item.isColor = true;
                isTrue = false;
            }
            let input = e.target.value;
            // 只允许输入数字和小数点
            input = input.replace(/[^0-9.]/g, "");
            if (input.includes(".")) {
                let integerPart = input.split(".")[0];
                let decimalPart = input.split(".")[1];

                // 限制小数部分最多为两位，整数部分最多为10位
                if (decimalPart.length > 2) {
                    decimalPart = decimalPart.slice(0, 2);
                }

                if (integerPart.length > 10) {
                    integerPart = integerPart.slice(0, 10);
                }

                input = integerPart + "." + decimalPart;
            } else {
                // 限制整数部分最多为10位
                if (input.length > 10) {
                    input = input.slice(0, 10);
                }
            }
            e.target.value = input;
        };

        const getAddressList = () => {
            let params = { uscUserId: store.state.memberInfo.uscUserId, pageSize: 1000 };
            proxy
                .$get("api/shopp/member/address/list", params)
                .then((res) => {
                    if (res.code == 200) {
                        address_list.data = res.data;
                        console.log(address_list);
                    } else {
                        confirmOrder(2);
                        ElMessage.success(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        //const pageNums = ref(0);
        const tableData = reactive({
            data: [],
            goodsList: [{ name: 1 }],
            needCount: {}
        });
        let param = reactive({
            memberId: store.state.memberInfo.memberId,
            memberPlanCode: "",
            source: "",
            prayName: "",
            startDate: "",
            endDate: "",
            resource: "",
            state: "",
            pageNum: 1,
            pageSize: 10
        });

        //去商品详情
        const goGoodsDetail = (e) => {
            router.push({
                name: "GoodsDetail",
                query: {
                    productId: e
                }
            });
        };

        //返回
        const goback = () => {
            window.location.href = "about:blank";
            window.close();
        };
        //获取详情数据
        const getDetailData = () => {
            console.log(route);
            proxy

                .$get("api/shopp/purchase/plan/queryPlan", {
                    memberPlanCode: route.query.memberPlanCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        topTable.data = res.data;
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        //切换地址
        const selectAddress = (index) => {
            current_address_index.value = index;
        };
        //确认修改地址
        const confirmChangeAddress = () => {
            let param = {
                memberPlanCode: topTable.data.memberPlanCode,
                receivingName: address_list.data[current_address_index.value].memberName,
                receivingMobile: address_list.data[current_address_index.value].telMobile,
                receivingAddress: `${address_list.data[current_address_index.value].addressAll},${
                    address_list.data[current_address_index.value].detailAddress
                }`,
                receivingAddressCode: `${address_list.data[current_address_index.value].provinceCode},${
                    address_list.data[current_address_index.value].cityCode
                },${address_list.data[current_address_index.value].districtCode},`
            };
            proxy.$post("api/shopp/purchase/plan/updatePlan", param, "json").then((res) => {
                if (res.code == 200) {
                    show_select_address.value = false;
                    getDetailData();
                } else {
                    ElMessage(res.msg);
                }
            });
            localStorage.setItem("addressId", address_list.data[current_address_index.value].addressId);
        };
        //弹出地址选择框
        const changeAddress = () => {
            getAddressList();
            show_select_address.value = true;
        };
        //确认修改地址

        //关闭计划
        const closeOrder = () => {
            let param = {
                memberPlanCode: route.query.memberPlanCode
            };

            proxy
                .$get("api/shopp/purchase/plan/closePurchasePlan", param)
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage({
                            message: "操作成功",
                            type: "success"
                        });
                        router.push("/member/procurement/procurementPlanList");
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };

        //编辑操作
        const handleEdit = () => {
            router.push({
                path: "/member/procurement/editQuotationDetail",
                query: {
                    memberPlanCode: topTable.data.memberPlanCode,

                    state: topTable.data.state,
                    goodsNum: route.query.goodsNum,
                    pageNum: 1,
                    pageSize: 10,
                    editType: 0
                }
            });
        };

        //关闭
        const handleCloseItem = (memberNeedCode) => {
            proxy
                .$get("api/shopp/purchase/plan/closePurchaseNeeds", {
                    memberNeedCode,
                    memberPlanCode: topTable.data.memberPlanCode
                })
                .then((res) => {
                    if (res.code == 200) {
                        ElMessage.success(res.msg);
                        getDetailGoodsData();
                    } else {
                        ElMessage(res.msg);
                    }
                })
                .catch(() => {});
        };

        //备注中查看图片
        const previewImg = (imgs) => {
            srcList.value = imgs.split(",");
            descImgVisible.value = true;
        };

        const getDetailGoodsData = () => {
            proxy
                .$get("api/shopp/purchase/plan/needDetails", {
                    memberPlanCode: route.query.memberPlanCode,
                    state: 0
                })
                .then((res) => {
                    if (res.code == 200) {
                        tableData.data = res.data;

                        tableData.data.forEach((item) => {
                            item.isColor = false;
                            item.borderColor = false;
                        });
                    }
                })
                .catch(() => {
                    //异常处理
                });
        };
        const handleFormValidate = async () => {
            if (!formRef.value) return false;
            return await formRef.value.validate().catch(() => false);
        };
        //提交
        const onSubmit = async (formEl) => {
            let params = {
                memberPlanCode: route.query.memberPlanCode,
                myQuoteReqList: tableData.data
            };
            let isTrue = true;
            let found = tableData.data.some((item) => item.myPrice || item.mySupplierName);

            tableData.data.forEach((item) => {
                if (item.borderColor == true || item.isColor == true) {
                    isTrue = false;
                }
            });
            if (!found) {
                isTrue = false;
                ElMessage({
                    message: "请填写我的报价",
                    type: "error"
                });
            }
            if (isTrue) {
                // alert("进来了")
                proxy
                    .$post("api/shopp/purchase/plan/insertMyQuote", params, "json")
                    .then((res) => {
                        if (res.code == 200) {
                            ElMessage({
                                message: "操作成功",
                                type: "success"
                            });
                            router.push({
                                path: "/member/procurement/procurementPlanList",
                                query: {
                                    //memberPlanCode: code,
                                    //state: state,
                                    pageNum: route.query.pageNum,
                                    pageSize: route.query.pageSize
                                }
                            });
                        } else {
                            ElMessage({
                                message: res.msg,
                                type: "error"
                            });
                        }
                    })
                    .catch(() => {
                        //异常处理
                    });
            }
        };
        const getmySupplierList = (memberNeedCode, list) => {
            mySupplier(mySupplierParam);

            setTimeout(() => {
                if (memberNeedCode !== null) {
                    SupplietParam.memberNeedCode = [memberNeedCode];
                } else {
                    list.forEach((item) => {
                        console.log(item.memberNeedCode, 99999);
                        SupplietParam.memberNeedCode.push(item.memberNeedCode);
                    });

                    SupplietParam.memberNeedCode = [...new Set(SupplietParam.memberNeedCode)];
                }
                SupplierVisible.value = true;
            }, 700);
        };
        const mySupplier = (Param) => {
            proxy.$get("api/shopp/supplier/querySupplier", Param).then((res) => {
                if (res.code == 200) {
                    console.log(res.data.records);

                    mySupplierList.data = res.data.records;

                    mySupplierParam.pageNum = res.data.pageNum;
                    mySupplierParam.pageSize = res.data.pageSize;
                    mySupplierParam.total = res.data.total;
                    return mySupplierParam;
                }
            });
        };
        const onSubmits = () => {
            mySupplier(mySupplierParam);
        };
        const resetForms = (formEl) => {
            if (!formEl) return;
            formEl.resetFields();
            mySupplierParam.pageNum = 1;
            mySupplierParam.time = "";
            mySupplierParam.startDate = "";
            mySupplierParam.endDate = "";
            mySupplier(mySupplierParam);
        };
        const handleSizeChanges = (val) => {
            mySupplierParam.pageSize = val;
            mySupplier(mySupplierParam);
            console.log(`${val} items per page`);
        };
        const handleCurrentChanges = (val) => {
            mySupplierParam.pageNum = val;

            mySupplier(mySupplierParam);
        };
        const inviteQuote = (list) => {
            if (list.length !== 0) {
                proxy
                    .$post(
                        "api/shopp/memberPurchasePlan/inviteMySupplier",
                        {
                            memberNeedCodeList: SupplietParam.memberNeedCode,
                            supplierIdList: list
                        },
                        "json"
                    )
                    .then((res) => {
                        if (res.code == 200) {
                            ElMessage({
                                message: "操作成功",
                                type: "success"
                            });
                            //SupplierVisible.value = false;
                            //SupplietParam.memberNeedCode = [];
                            SupplietParam.supplierIdList = [];
                            mySupplierParam.pageNum = 1;
                            mySupplierParam.pageSize = 10;
                        }
                    });
            } else {
                ElMessage({
                    message: "请选择供应商",
                    type: "warning"
                });
            }
        };
        const toSupplierHomePage = (id, name) => {
            console.log(id, name);
            router.push({
                path: "/goods/list",
                query: {
                    supplierId: id,
                    supplierUserName: name
                }
            });
        };
        const handleSelectionChange = (val) => {
            console.log(val);
            val.forEach((item) => {
                SupplietParam.supplierIdList.push(item.id);
            });
            SupplietParam.supplierIdList = [...new Set(SupplietParam.supplierIdList)];
        };
        const addSupplier = () => {
            router.push("/member/mySupplier/addSupplier");
        };
        onMounted(() => {
            if (route.query.orderState) {
                current_state.value = route.query.orderState;
            } else {
                current_state.value = "";
            }
            if (route.query.evaluateState) {
                evaluate_state.value = route.query.evaluateState;
            } else {
                evaluate_state.value = "";
            }

            getDetailData();
            getDetailGoodsData();
        });
        const tableHeaderColor = (data) => {
            console.log(data, 34343);
            return {
                background: "#F6F6F6FF",
                color: "#333333FF",
                fontWeight: "bold"
            };
        };
        return {
            L,
            param,
            tableData,
            fits,
            multipleTableRef,
            multipleSelection,
            descImgVisible,
            AllMoney,
            OtherList,
            srcList,
            previewImg,
            goback,
            closeOrder,
            handleEdit,
            handleCloseItem,
            addressVisibleFlag,
            addAddress,
            refreshAddress,
            close,
            type,
            show_select_address,
            confirmChangeAddress,
            selectAddress,
            changeAddress,
            tableHeaderColor,
            payData,
            orderBelongsVal,
            selectDate,
            activeIndex,
            not_select,
            selected,
            invoice_selected,
            current_address_index,
            changeAddress,
            address_list,
            getAddressList,
            getDetailData,
            handleChange,
            goGoodsDetail,
            //toggleSelection
            dialogVisible,
            debounce,
            validateNumber,
            rules,
            topTable,
            getDetailGoodsData,
            onSubmit,
            ruleFormRef,
            mySupplierNameHandleChange,
            route,
            router,
            platform,
            store,
            getmySupplierList,
            SupplietParam,
            mySupplierList,
            SupplierVisible,
            mySupplierParam,
            onSubmits,
            resetForms,
            handleCurrentChanges,
            handleSizeChanges,
            inviteQuote,
            toSupplierHomePage,
            handleSelectionChange,
            addSupplier
        };
    }
};
</script>
<style lang="scss" scoped>
@import "../../../style/waitQuotationDetail.scss";

.clickBtn {
    color: $colorMain;
    cursor: pointer;
}
.el-dialog .el-dialog__body .desc-image__preview {
    .el-image {
        width: 148px;
        height: 148px;
    }
    .active {
        margin-left: 10px;
    }
}
.desc-clss {
    white-space: nowrap; /* 确保文本在一行内显示 */
    overflow: hidden; /* 隐藏溢出容器的文本 */
    text-overflow: ellipsis; /* 使用省略号表示文本溢出 */
    max-width: 100px; /* 设置最大宽度 */
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0e3177 !important;
    border-color: #0e3177 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #0e3177 !important;
    border-color: #0e3177 !important;
}
.sld_member_main_content[data-v-61d93b18] {
    background: #f8f8f8;
}
.sld_order_list .container {
    /*width: 1207px;*/
}
.sld_order_list {
    /*width: 1210px;*/
    width: 100%;
    background: #fff;
}
.el-pager li.active {
    color: $colorMain;
    cursor: default;
}

.el-pager li:hover {
    color: $colorMain;
}

.el-select-dropdown__item.selected {
    color: $colorMain;
}

.sld_order_list {
    color: #000;
    .sld_List {
        color: #000;
    }
    /*.el-dialog__header {
    background-color: $colorMain;
    padding: 11px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-dialog__title {
      color: #fff;
      font-size: 14px;
    }
    .el-dialog__headerbtn {
      top: auto;
    }
    .el-dialog__headerbtn .el-dialog__close {
      color: #fff !important;
      font-size: 18px;
    }
  }
  .el-dialog {
    overflow: hidden;
    border-radius: 4px;
  }
  .el-dialog__footer {
    display: flex;
    // justify-content: center;
    align-items: center;
  }*/
    /*.aft_btn:hover {
    background-color: rgba($color: #f30213, $alpha: 0.2);
    color: #f30213;
  }*/

    .aft_btn:focus {
        background-color: #fff;
        color: #333333;
        border-color: #dcdfe6;
    }
    .dia_btn {
        .el-button {
            width: 134px;
            height: 36px;
        }
    }
    .downLoad_contract {
        width: 150px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 1px solid #e8e8f1;
        border-radius: 5px;
        cursor: pointer;
    }
    .downLoad_contract:hover {
        border: 1px solid $colorMain;
    }
}
.container {
    .bootom-footer {
        text-align: right;
    }
    .bootom-footer button:first-child {
        margin-right: 10px;
    }
    // .el-input {
    //   width: 300px;
    // }
    .unit {
        font-size: 18px;
        margin-left: 10px;
    }
}
.demo-form-inline .el-input {
    --el-input-width: 220px;
}
.el-table thead {
    color: #5f5f60;
}
.el-table {
    color: #2b2b2c;
}
.result {
    height: 100%;
    margin-top: 30px;
    text-align: center;
    padding: 0px 20px 50px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 16px;
    margin-bottom: 40px;
}
.child {
    width: 100%;
    display: flex;
    align-content: center;
    .goodsMassage {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 92%;
        align-items: center;
        .operationContent {
            width: 100%;
            line-height: 2;
            .goodsContent {
                display: flex;
                p {
                    width: 30%;
                    line-height: 2.5;
                }
            }
        }
    }
    .goodsMassages {
        margin-left: 10px;
        display: flex;
        justify-content: space-between;
        width: 90%;
        /*align-items: ;*/
        .operationContent {
            width: 100%;
            line-height: 2;
            .goodsContent {
                display: flex;
                p {
                    width: 30%;
                    line-height: 2.5;
                }
            }
        }
    }
}
.showContent {
    margin-top: 20px;
    padding: 10px;
    background: rgb(250, 247, 247);
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
}
.receive_info {
    border: 1px solid #dddddd;
    border-radius: 2px;
    margin-bottom: 30px;
    .pre_message_title,
    .receive_info_title {
        height: 40px;
        line-height: 40px;
        font-size: 12px;
        padding-left: 20px;
        background-color: #f8f8f8;
        color: #666666;
    }
    .receive_info_content {
        height: 159px;
        padding: 0 40px;
    }

    .pre_message_info {
        padding: 10px 40px;
        .tag_pre {
            display: inline-block;
            line-height: 25px;
            text-align: right;
            font-weight: 400;
            margin-right: 10px;
            font-size: 14px;
            color: #333333;
            display: flex;
            min-width: 100px;
            max-width: 200px;
            justify-content: flex-end;
        }

        strong {
            font-size: 14px;
            color: $colorMain;
            margin-right: 3px;
        }
    }
    .content_left {
        width: 785px;
        /*border-right: 1px solid #dddddd;*/
        .member {
            font-size: 14px;
        }
        .address_detail {
            width: 700px;
        }
        span:not(:first-child) {
            font-size: 14px;
            line-height: 14px;
            margin-top: 22px;
        }
    }
    .content_right {
        width: 370px;
        font-size: 14px;
        border-left: 1px solid #dddddd;
        padding-left: 10px;
        .replace_address {
            color: $colorMain2;
            line-height: 14px;
        }
    }
    .add_address {
        width: 100px;
        height: 30px;
        background-color: $colorMain;
        color: white;
        line-height: 30px;
        text-align: center;
        margin-top: 29px;
        border-radius: 3px;
    }
    .nomartop {
        margin-top: 0;
    }
}
.select_address_width {
    width: 500px !important;
}
.overflow_ellipsis_clamp2 {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-all;

    line-height: 19px;
}
.out_stock_dialog {
    width: 460px;
    margin: 0 auto;
    height: 330px;
    overflow-y: scroll;
    .good_item {
        font-size: 14px;

        img {
            width: 80px;
            height: 80px;
        }
        .good_info {
            margin-left: 10px;
        }
        .good_name {
            width: 320px;
            color: #333333;
            line-height: 14px;
            margin-top: 10px;
            display: inline-block;
        }
        .spec_num {
            margin-top: 26px;
            .good_spec {
                color: #999999;
            }
            .good_num {
                float: right;
                color: #333333;
            }
        }
    }
}
.address_con {
    height: 330px;
    overflow-y: scroll;
    scrollbar-width: none !important;
    -ms-overflow-style: none !important;
    .address_item {
        &:not(:first-child) {
            margin-top: 20px;
        }
        width: 458px;
        //    height: 104px;
        box-sizing: border-box;
        border: 1px solid #dfdfdf;
        position: relative;
        padding: 20px;
        span,
        div:not(:first-child) {
            margin-top: 12px;
        }
        .address_text {
            display: flex;
            width: 400px;
        }
        .selected {
            width: 14px;
            height: 14px;
            position: absolute;
            right: 0;
            bottom: 0;
        }
    }
    .select {
        border: 1px solid $colorMain2;
    }
}
.red {
    background-color: $colorMain;
    color: white;
}
.remove {
    width: 120px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 3px;
    background-color: $colorMain;
    margin-left: 10px;
    color: white;
}
.replace_address {
    color: $colorMain2;
    line-height: 14px;
}
.Submit {
    background: #0e3177;
    width: 70.5px;
    height: 38.5px;
    cursor: pointer;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 38.5px;
    font-size: 14px;
}
.tabs {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    color: #fff;
    background: #fff;
    border: 1px solid #cfcfcfff;
    margin-right: 10px;
    font-size: 14px;
}
.btnActive {
    background: #0e3177;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    line-height: 20px;
    text-align: center;
    color: #fff;
    margin-right: 10px;
    font-size: 14px;
}
.my-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e3e2e2;
    padding: 13px 20px 0px 15px;
    background-color: $colorMain;
    color: white;
    padding-bottom: 10px;
}
/*::v-deep .el-dialog__header {
  padding: 0px;
  padding-bottom: 0px;
}*/
.modalTitle {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    img {
        width: 27px;
        height: 24px;
        /*margin-right: 5px;*/
    }
}
.Clear {
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #5e5858;
    line-height: 35px;
    font-size: 14px;
    border: 1px solid #ccc;
    cursor: pointer;
}
.Submit2 {
    background: #0e3177;
    width: 76px;
    height: 35px;
    border-radius: 4px;
    text-align: center;
    color: #fff;
    margin-left: 10px;
    line-height: 35px;
    font-size: 14px;
    cursor: pointer;
}
.invoice_con {
    height: 100px;
    display: flex;
    justify-content: center;
    .activeLt {
        margin-left: 20px;
    }
    .no_select_invoice {
        cursor: pointer;
        img {
            width: 18px;
            height: 18px;
        }
        span {
            font-size: 14px;
            line-height: 14px;
            margin-left: 10px;
        }
        .invoice_info {
            margin-left: 50px;
            border-left: 1px solid #dddddd;
            span {
                font-size: 14px;
                line-height: 14px;
                margin-left: 50px;
            }
            .choose {
                color: #257bfd;
                cursor: pointer;
            }
        }
        .block {
            margin-left: 10px;
            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }
            .el-input__inner {
                padding-left: 40px;
            }
        }
        .date_box {
            margin-left: 10px;
            ::v-deep .el-date-editor.el-input {
                width: 160px;
            }
        }
    }
}
.searchBtn2 {
    cursor: pointer;
    display: flex;
    /*width: 200px;*/
    position: absolute;
    top: 3%;
    right: 0%;
    .search {
        width: 56px;
        height: 32px;
        line-height: 32px;
        background: #0e3177;
        cursor: pointer;
        text-align: center;
        border-radius: 3px;
        color: #fff;
        margin-right: 10px;
    }
}
.search2 {
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #0e3177;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    color: #fff;
    margin-right: 10px;
}
.search3 {
    width: 100px;
    height: 32px;
    line-height: 32px;
    background: #fff;
    border: 1px solid #0e3177;
    cursor: pointer;
    text-align: center;
    border-radius: 3px;
    color: #0e3177;
    margin-right: 10px;
}
</style>
